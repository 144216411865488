import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1638997547/Rep-dominicana_rwdg0u.png",
        "alt": "República Dominicana"
      }}></img></p>
    <p>{`El año pasado salió en Netflix una docuserie sobre la historia del Rock en Latinoamérica. Tal material hacía un recorrido desde la década de los sesenta hasta nuestros días y fue severamente criticado por el periodismo musical por enfocarse en México, Argentina, Chile y Colombia -como si esos fueran TODA Latinoamérica-. El mismo fenómeno sucede cuando hablamos de literatura latinoamericana en la que se subrayan los mismos países, y quizá un par más.`}</p>
    <p>{`Dando por hecho que estos países destacan sobre el resto por su proceso político-socioeconómico no deberíamos de olvidar que Latinoamérica está conformada por 46 países, incluyendo las islas y los países del Caribe. ¿Pero qué sabemos sobre ellos, en realidad? ¿Nuestro documental sobre literatura latinoamericana y del Caribe estaría menos sesgado que el de música de nuestro amigo Netflix?`}</p>
    <p>{`Esta columna, en un principio se concibió para hablar sobre la literatura del Caribe, pero me di cuenta que no tenía nada qué decir porque no conocía nada sobre ella. Algunas cosas sobre la literatura cubana y nada más, así que decidí investigar y compartir con ustedes, queridos lectores, las conclusiones, o más bien preguntas que me surgieron en el proceso.`}</p>
    <p>{`Para agilizar este texto nos vamos a saltar siglos de historia y asumiremos que los países caribeños de habla hispana son Cuba, Puerto Rico y República Dominicana. Una vez más, por razones políticas-socioeconómicas, (aka la Revolución y sus consecuencias) la literatura de Cuba sobresale de sus hermanas puertorriqueña y dominicana por lo que no escribiré nada al respecto. A la literatura puertorriqueña la dejaremos para después -cuando investigue más al respecto-, por lo que nos queda la literatura dominicana.`}</p>
    <p>{`Toda la literatura caribeña se considera como tal desde sus respectivas independencias, por lo que la dominicana empieza a existir en algún momento entre 1821 y 1844. Las temáticas de entonces iban sobre la identidad, la negritud, el mestizaje y la relación del uno con el otro y con el paisaje. Con el paso del tiempo, estas temáticas van metamorfoséandose aunque la cuestión identitaria parece siempre estar presente, sobre todo por la vecindad con Haití.`}</p>
    <p>{`Para el siglo XX, a partir de la dictadura de Trujillo, inicia la diáspora dominicana y miles de personas emigran hacia otras latitudes. Durante esta migración, la familia de Junot Díaz llega a Nueva York. `}<strong parentName="p">{`Díaz es quizá uno de los representantes más importantes de la literatura dominicana actual, al menos es el más famoso; en 2008 ganó el premio Pulitzer por su novela `}<em parentName="strong">{`La maravillosa vida breve de Óscar Wao`}</em>{`.`}</strong></p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1638997549/junot_qw7juc.png",
        "alt": "Junot-Díaz"
      }}></img></p>
    <p>{`Como migrante, Díaz escribe sobre la diáspora, sobre familias dominicanas que residen en Estados Unidos, escribe sobre la identidad bipartita y, cosa importante, escribe en inglés mezclado con español. El español, tan identitario de estas tierras latinas pasa a un segundo plano en las ficciones de Díaz. Según el propio escritor, esto se debe a que él mismo lee y fue educado en inglés, el español es el idioma que utiliza con su familia y amigos, en el ámbito privado. Sin embargo, se asoma como destellos idiomáticos de frases intraducibles.`}</p>
    <p>{`Si la literatura dominicana está tomando fuerza en latitudes lejos de la propia isla, pero los temas se mantienen y se mezclan con una nueva cultura, sería pertinente preguntarnos qué vuelve nacional a una literatura. Junot Díaz tiene ambas nacionalidades y sus personajes son migrantes dominicanos que hablan en inglés.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`¿Una literatura nacional tiene que ver con sus personajes o con el escritor?`}</strong></p>
    </blockquote>
    <p>{`A México, por dar un ejemplo, arribó un joven Roberto Bolaño huyendo de la dictadura de Pinochet y aunque escribía de la Ciudad de México y Ciudad Juárez se considera un escritor chileno. Así, pues, la literatura dominicana de Julia Álvarez (nacida en Estados Unidos de padres dominicanos) o el ya mencionado Junot no sería menos dominicana que la de Marcio Veloz Maggiolo, quien hizo toda su carrera en Santo Domingo.`}</p>
    <p>{`Así pues, la literatura del Caribe, en este caso la dominicana, se yergue como una tradición basada en sortear dificultades. Aunque se tenga que adoptar otro idioma para sobrevivir; los temas, la cultura familiar y propia, los personajes no pierden la esencia latina, caribeña, nacional.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      